import React from "react";
import Field from "./field.js";

const Checkbox = (props) => {
  const validationFunction = (value) => {
    return value.length > 0;
  };
  return (<Field
    {...props}
    isGroup={ true }
    multiple={ true }
    defaultValue={ props.choices.filter(e => e.isSelected).map(e => e.value) }
    defaultClassName="checkbox-group"
    validationFunction={ validationFunction }
  />);
};

export default Checkbox;
